
function setCookie(cookieName,cookieValue) {
  var name =cookieName;
  var value=cookieValue;
  console.log(cookieName);
  console.log(cookieValue);
  let days=30;
  var domain, domainParts, date, expires, host;

      if (days)
      {
         date = new Date();
         date.setTime(date.getTime()+(days*24*60*60*1000));
         expires = "; expires="+date.toGMTString();
      }
      else
      {
         expires = "";
      }
      host = location.host; //"weblogin-uat.5paisa.com";
      if (host.split('.').length === 1)
      { 
         document.cookie = name+"="+value+expires+";Secure; path=/";
      }
      else
      { 
         domainParts = host.split('.');
         domainParts.shift();
         domain = '.'+domainParts.join('.');
         

         //document.cookie = name+"="+value+expires+"; path=/ HttpOnly; Secure; SameSite=Lax; domain="+domain;
         document.cookie = name+"="+value+expires+";Secure; path=/; domain="+domain;
         
        // check if cookie was successfuly set to the given domain
        // (otherwise it was a Top-Level Domain)
         //  if (Cookie.get(name) == null || Cookie.get(name) != value)
         //  {
         //    // append "." to current domain
         //    domain = '.'+host;
         //    document.cookie = name+"="+value+expires+"; path=/; domain="+domain;
         // }
      }
}


